import React from "react";
import { useNavigate } from "react-router-dom";
import { useSetState } from "react-use";

export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  username: "",
};

const userLogin = async (username, password) => {
  if (username === "admin" && password === "admin")
    return { username: "admin" };
  return null;
};

export const ContextProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useSetState(initialState);

  const setLoginPending = (isLoginPending) => setState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
  const setLoginError = (loginError) => setState({ loginError });
  const setUsername = (username) => setState({ username });

  const login = async (username, password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    const user = await userLogin(username, password);
    setLoginPending(false);
    if (user == null) {
      setUsername("");
      alert("Login is invalid");
      return;
    }
    setUsername(username);
    setLoginSuccess(true);
    navigate("/");
  };

  const logout = () => {
    setUsername("");
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
