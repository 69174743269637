import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../context/userContext";

function PrivateRoute() {
  const location = useLocation();
  const { state } = useContext(AuthContext);
  if (!state.isLoggedIn)
    return <Navigate to='/login' replace state={{ from: location }} />;
  else return <Outlet />;
}

export default PrivateRoute;
